import React, {Component} from 'react';
import './App.css';
import {Button, Container, Row, Col, ButtonGroup} from 'react-bootstrap';
import canvg from 'canvg';

class App extends Component {

    state = {
        file: null,
        svg: null,
        large: {
            width: 219,
            height: 302
        },
        small: {
            width: 219,
            height: 151
        },
        isCanvasCreated: false,
        importedFile: {
            width: null,
            height: null
        }
    };

    onChangeUpload = event => {

        if (event.target.files[0]) {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const svg = e.target.result;

                const parser = new DOMParser();
                const doc = parser.parseFromString(svg, "image/svg+xml");
                const svgWidth = doc.getElementsByTagName("svg")[0].getAttribute('width');
                const svgHeight = doc.getElementsByTagName("svg")[0].getAttribute('height');

                this.setState({svg: svg, importedFile: {width: svgWidth, height: svgHeight}});
            };
            fileReader.readAsText(event.target.files[0]);
            const file = event.target.files[0];
            this.setState({file});
        }
    };

    showUpload = () => {
        this.setState({svg: null, file: null, isCanvasCreated: false, importedFile: {width: null, height: null}});
        document.getElementById('uploadInput').click();
    };

    svgToCanvas = (svg) => {
        const canvas = document.getElementById('canvas');
        canvg(canvas, svg, {useCORS: true});

        this.setState({isCanvasCreated: true});
    };

    onLargeClick = () => {
        const {svg, large} = this.state;

        if (svg) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(svg, "image/svg+xml");
            doc.getElementsByTagName("svg")[0].setAttribute('width', large.width);
            doc.getElementsByTagName("svg")[0].setAttribute('height', large.height);
            this.setState({svg: (new XMLSerializer()).serializeToString(doc)});
            this.svgToCanvas((new XMLSerializer()).serializeToString(doc));
        }
    };

    onSmallClick = () => {
        const {svg, small} = this.state;

        if (svg) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(svg, "image/svg+xml");
            doc.getElementsByTagName("svg")[0].setAttribute('width', small.width);
            doc.getElementsByTagName("svg")[0].setAttribute('height', small.height);
            this.setState({svg: (new XMLSerializer()).serializeToString(doc)});
            this.svgToCanvas((new XMLSerializer()).serializeToString(doc));
        }
    };

    donwloadSvg = () => {
        const {svg, file} = this.state;

        if (svg && file) {
            const fileName = file.name.substring(0, file.name.length - 4);
            const canvas = document.getElementById("canvas");
            const img = canvas.toDataURL("image/jpg");

            let tempLink = document.createElement('a');
            tempLink.href = img;
            tempLink.setAttribute('download', fileName + '.jpg');
            tempLink.click();
        }
    };

    render() {
        const {file, isCanvasCreated, importedFile} = this.state;

        let sizeButtons, downloadButton;

        if (file) {
            if (importedFile.width === "350" && importedFile.height === "553") {
                sizeButtons = <ButtonGroup vertical>
                    <Button variant="info" onClick={this.onLargeClick}>58mmx80mm</Button>
                </ButtonGroup>
            }

            if (importedFile.width === "686" && importedFile.height === "472") {
                sizeButtons = <ButtonGroup vertical>
                    <Button variant="info" onClick={this.onSmallClick}>58mmx40mm</Button>
                </ButtonGroup>
            }
        }

        if (isCanvasCreated) {
            downloadButton = <Button variant="success" onClick={this.donwloadSvg}>Télécharger l'image en JPG</Button>
        }


        return (
            <Container style={{marginTop: '50px'}}>
                <h1 style={{textAlign: 'center'}}>Convertir une image SVG en JPG pour EasyPub</h1>
                <Row style={{marginTop: '40px'}}>
                    <Col>
                        <p style={{fontSize: '15px'}}>Étape 1 : Sélectionner le fichier SVG depuis votre ordinateur</p>
                    </Col>
                    <Col>
                        <p style={{fontSize: '15px'}}>Étape 2 : Sélectionner le format (58x80 ou 58x44)</p>
                    </Col>
                    <Col>
                        <p style={{fontSize: '15px'}}>Étape 3 : Télécharger le fichier en JPG</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={this.showUpload}>Importer le fichier SVG</Button>
                        <input type="file" accept=".svg" id="uploadInput" onChange={this.onChangeUpload}
                               style={{display: 'none'}}/>
                    </Col>
                    <Col>
                        {sizeButtons}
                    </Col>
                    <Col>
                        {downloadButton}
                    </Col>
                </Row>
                <Row style={{marginTop: '40px', display: 'none'}}>
                    <h2>Render :</h2>
                    <canvas id="canvas"/>
                </Row>
            </Container>
        );
    }
}

export default App;
